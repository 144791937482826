import { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { produce } from 'immer'

import { api, apiHooks } from 'api'

import { Download, DownloadCreate } from '../types'

export const useDownloads = () => {
  return apiHooks.useGet('/downloads', { queries: { acknowledged: false } })
}

export const useListenDownloads = () => {
  const [isDownloadsUnfinished, setDownloadsUnfinished] = useState(true)
  const { data } = apiHooks.useGet(
    '/downloads',
    {
      queries: { acknowledged: false },
    },
    { refetchInterval: isDownloadsUnfinished ? 10000 : false },
  )

  useEffect(() => {
    if (data === undefined) return
    // check if any of the downloads are not acknowledged
    const isUnfinished = data.some((download) => !download.acknowledged_at)

    setDownloadsUnfinished(isUnfinished)
  }, [data])

  return { data }
}

export const useDownloadsCreate = () => {
  const queryClient = useQueryClient()

  const key = apiHooks.getKeyByPath('get', '/downloads', {
    queries: { acknowledged: false },
  })

  return useMutation(
    ({ download }: { download: DownloadCreate }) =>
      api.post('/downloads', download),
    {
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(key)
      },
    },
  )
}

export const useDownloadsAcknowledge = () => {
  const queryClient = useQueryClient()

  const key = apiHooks.getKeyByPath('get', '/downloads', {
    queries: { acknowledged: false },
  })

  return useMutation(
    ({ download }) =>
      api.patch(
        '/downloads/:id',
        { acknowledged: true },
        { params: { id: download._id } },
      ),
    {
      // When mutate is called:
      onMutate: async ({ download }: { download: Download }) => {
        queryClient.cancelQueries(key)

        const previousDownloads = queryClient.getQueryData(key) as Download[]

        const newDownloads = produce(previousDownloads, (draft) => {
          const index = draft.findIndex((t) => t._id === download._id)
          draft.splice(index, 1)
        })

        queryClient.setQueryData(key, newDownloads)

        return { previousDownloads }
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(key)
      },
    },
  )
}

export const useDownloadsDelete = () => {
  const queryClient = useQueryClient()

  const key = apiHooks.getKeyByPath('get', '/downloads', {
    queries: { acknowledged: false },
  })

  return useMutation(
    ({ download }) =>
      api.delete('/downloads/:id', undefined, { params: { id: download._id } }),
    {
      // When mutate is called:
      onMutate: async ({ download }: { download: Download }) => {
        queryClient.cancelQueries(key)

        const previousDownloads = queryClient.getQueryData(key) as Download[]

        const newDownloads = produce(previousDownloads, (draft) => {
          const index = draft.findIndex((t) => t._id === download._id)
          draft.splice(index, 1)
        })

        queryClient.setQueryData(key, newDownloads)

        return { previousDownloads }
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(key)
      },
    },
  )
}
