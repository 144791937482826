import * as ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import * as serviceWorker from 'lib/service-worker'
import App from 'features/app/pages/App'

import './index.css'

Sentry.init({
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  dsn: 'https://33541389e9194800b2d674b739fd5f1b@o1354496.ingest.sentry.io/6638224',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
