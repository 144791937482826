import de from 'i18n/messages/de.json'
import en from 'i18n/messages/en.json'
import es from 'i18n/messages/es.json'

export type Locale = 'en' | 'de' | 'es'

export const isSupportedLocale = (locale: string): locale is Locale => {
  return ['en', 'de', 'es'].includes(locale)
}

type Messages = {
  en: typeof en
  de: typeof de
  es: typeof es
}

export const messages: Messages = {
  en,
  de,
  es,
}
