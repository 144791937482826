import axios from 'axios'
import { stringify } from 'qs'

import { keycloak } from 'lib/keycloak'

export const baseURL = `${import.meta.env.VITE_REPLAY_API}/replay/v1/`

export const replay = axios.create({
  baseURL,
  paramsSerializer: {
    serialize: (params) => stringify(params, { arrayFormat: 'comma' }),
  },
})

replay.interceptors.request.use((config) => {
  if (!keycloak.token) return config

  // eslint-disable-next-line no-param-reassign
  config.headers = {
    Authorization: `Bearer ${keycloak.token}`,
    ...config.headers,
  }

  return config
})
