/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MobileStepper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import {
  CloseSharp as CloseIcon,
  KeyboardArrowLeftSharp as KeyboardArrowLeftIcon,
  KeyboardArrowRightSharp as KeyboardArrowRightIcon,
} from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'
import { useState } from 'react'

import { IntroductionModalObj } from 'features/app/hooks/useIntroductionModal'
import { useResponsive } from 'features/app/hooks/useResponsive'

type IntroductionModalProps = {
  modal: IntroductionModalObj
  onClose: () => void
}

export const IntroductionModal = ({
  modal,
  onClose,
}: IntroductionModalProps) => {
  const { isMobile } = useResponsive()
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = modal.steps.length

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      onClose()
    } else {
      setActiveStep(activeStep + 1)
    }
  }
  const handlePrev = () => {
    setActiveStep(activeStep - 1)
  }

  return (
    <Dialog open onClose={onClose} fullScreen={isMobile} maxWidth="lg">
      <DialogTitle>
        <FormattedMessage id={`introductionModal.${modal.id}.title`} />
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          {isMobile && (
            <MobileStepper
              activeStep={activeStep}
              steps={maxSteps}
              nextButton={
                <Button size="small" onClick={handleNext}>
                  {activeStep === modal.steps.length - 1 ? 'Finish' : 'Next'}{' '}
                  <KeyboardArrowRightIcon />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handlePrev}
                  disabled={activeStep === 0}
                >
                  <KeyboardArrowLeftIcon /> Back
                </Button>
              }
            />
          )}

          {!isMobile && (
            <Stepper activeStep={activeStep} style={{ marginBottom: '20px' }}>
              {modal.steps.map((step) => {
                return (
                  <Step key={step.title}>
                    <StepLabel>
                      <FormattedMessage
                        id={`introductionModal.${modal.id}.${step.title}`}
                      />
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          )}

          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                minWidth: '70vh',
                minHeight: '52.5vh',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <video
                src={`${modal.steps[activeStep].video}`}
                style={{
                  position: 'absolute',
                  maxHeight: '52.5vh',
                  maxWidth: '90vw',
                  left: '0',
                  top: '0',
                }}
                autoPlay
                loop
                muted
              />
            </div>
            <Typography>
              <FormattedMessage
                id={`introductionModal.${modal.id}.${modal.steps[activeStep].text}`}
              />
            </Typography>
            {!isMobile && (
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handlePrev}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Button onClick={handleNext}>
                  {activeStep === modal.steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            )}
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
