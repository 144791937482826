import {
  Alert,
  Badge,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Snackbar,
} from '@mui/material'
import {
  CloseSharp as CloseIcon,
  DownloadSharp as DownloadIcon,
} from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { theme } from 'styles/theme'

import {
  useDownloadsAcknowledge,
  useDownloadsDelete,
  useListenDownloads,
} from '../api/hooks'

const downloadFile = async (name: string, file: string) =>
  new Promise((resolve) => {
    setTimeout(() => {
      // Create a link and set the URL using `createObjectURL`
      const link = document.createElement('a')
      link.id = `download-link-${name}`
      link.style.display = 'none'
      link.href = file
      link.download = name
      link.target = '_blank'

      // It needs to be added to the DOM so it can be clicked
      document.body.appendChild(link)

      link.click()

      URL.revokeObjectURL(link.href)
      link.parentNode?.removeChild(link)
      resolve(null)
    }, 0)
  })

const downloadedFiles = new Set<string>()

export const DownloadsMenu = () => {
  const [isOpen, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const [error, setError] = useState<string>()

  const { data: downloads } = useListenDownloads()

  const { mutateAsync: mutateAcknowledge } = useDownloadsAcknowledge()

  const { mutateAsync: mutateDelete } = useDownloadsDelete()

  useEffect(() => {
    const handleFinished = async () => {
      if (!downloads) return

      const doneDownloads = downloads.filter(
        (d) =>
          (d.status === 'finished' || d.status === 'error') &&
          !downloadedFiles.has(d._id),
      )

      if (doneDownloads.length === 0) return

      const errorCount = await downloads.reduce(async (acc, download) => {
        const count = await acc

        if (download.status === 'finished' && download.download_url)
          await downloadFile(download.name, download.download_url)

        if (download.status === 'error') return count + 1

        downloadedFiles.add(download._id)

        return count
      }, Promise.resolve(0))

      // this will trigger a re-render and therefore we need to make sure with `doneDownloads` that we don't re-download these files
      await Promise.all(
        doneDownloads.map((download) => mutateAcknowledge({ download })),
      )

      if (errorCount > 0) {
        setError('downloads.error')
      }
    }
    handleFinished()
  }, [mutateAcknowledge, downloads])

  useEffect(() => {
    if (downloads && downloads.length !== 0) return
    setAnchorEl(null)
    setOpen(false)
  }, [downloads])

  if (!downloads) return null

  if (downloads.length === 0 && !error) return null

  const allFinished = downloads.every((d) => d.status === 'finished')

  const finishedCount = downloads.filter((d) => d.status === 'finished').length

  const totalProgress =
    downloads.reduce((acc, d) => acc + (d.progress ?? 0), 0) / downloads.length

  return (
    <>
      <IconButton
        aria-label="download"
        color="inherit"
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          setOpen(true)
        }}
        style={{
          position: 'fixed',
          right: '16px',
          bottom: '16px',
          zIndex: 100,
          backgroundColor: theme.palette.background.paper,
          borderRadius: '8px',
        }}
      >
        <Badge badgeContent={finishedCount} color="secondary">
          <div className="relative flex">
            <DownloadIcon />
            {!allFinished && (
              <CircularProgress
                size={28}
                className="absolute -top-0.5 -left-0.5 aspect-square"
                variant={totalProgress === 0 ? 'indeterminate' : 'determinate'}
                color="secondary"
                value={totalProgress * 100}
              />
            )}
          </div>
        </Badge>
        <span className="p-1 text-sm">
          <FormattedMessage id="playlists.event_download_preapare" />
        </span>
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
          setOpen(false)
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          {downloads.map((d) => (
            <ListItem
              key={d._id}
              secondaryAction={
                <IconButton
                  onClick={async () => {
                    await mutateDelete({ download: d })
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              <ListItemIcon className="mr-2 min-w-0">
                <div className="relative flex">
                  <DownloadIcon className="p-1" />
                  {(d.status === 'pending' || d.status === 'processing') && (
                    <CircularProgress
                      size={24}
                      className="absolute inset-0 aspect-square"
                      variant={
                        d.status === 'pending' ? 'indeterminate' : 'determinate'
                      }
                      value={(d.progress ?? 0) * 100}
                    />
                  )}
                </div>
              </ListItemIcon>
              <ListItemText
                primary={
                  <FormattedMessage
                    id="downloads.prepare"
                    values={{
                      name: d.name,
                    }}
                  />
                }
              />
            </ListItem>
          ))}
        </List>
      </Popover>
      <Snackbar
        open={error !== undefined}
        autoHideDuration={6000}
        onClose={() => {
          setError(undefined)
        }}
      >
        <Alert severity="error">
          <FormattedMessage id={error ?? 'downloads.error'} />
        </Alert>
      </Snackbar>
    </>
  )
}
