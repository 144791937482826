import { db } from 'lib/dexie'

export const clearPlaylistFromCache = async (params: {
  playlistId: string
  eventIds: string[]
}) => {
  const { playlistId, eventIds } = params

  await db.clips.where('eventId').anyOf(eventIds).delete()
  await db.playlists.where('playlistId').anyOf([playlistId]).delete()
}

export const clearOldPlaylistsFromCache = async () => {
  const oneWeekInMs = 1000 * 60 * 60 * 24 * 7
  const now = Date.now()

  await db.playlists
    .where('timestamp')
    .belowOrEqual(now - oneWeekInMs)
    .delete()
  await db.clips
    .where('timestamp')
    .belowOrEqual(now - oneWeekInMs)
    .delete()
}
