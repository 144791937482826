import { Navigate, Route } from '@tanstack/react-location'

export const routes: Route[] = [
  // matches
  {
    path: 'matches',
    children: [
      {
        path: '/',
        import: () =>
          import('features/matches/routes/matchesModule').then(
            (module) => module.matchesModule,
          ),
      },
      {
        path: 'add',
        import: () =>
          import('features/matches/routes/addLocalMatchModule').then(
            (module) => module.addLocalMatchModule,
          ),
      },
      {
        path: ':matchId',
        children: [
          {
            path: '/',

            import: () =>
              import('features/matches/routes/matchDetailModule').then(
                (module) => module.matchDetailModule,
              ),
          },
          {
            path: 'stats',
            children: [
              {
                path: ':teamId',
                children: [
                  {
                    path: '/',
                    import: () =>
                      import('features/matches/routes/statsModule').then(
                        (module) => module.statsModule,
                      ),
                  },
                  {
                    path: '/review',
                    children: [
                      {
                        path: '/',
                        import: () =>
                          import(
                            'features/review/routes/statsCorrectionModule'
                          ).then((module) => module.statsCorrectionModule),
                      },
                      {
                        path: ':situationIndex',
                        import: () =>
                          import(
                            'features/review/routes/statsCorrectionModule'
                          ).then((module) => module.statsCorrectionModule),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  // livetagging
  {
    path: 'live',
    children: [
      {
        path: '/',
        import: () =>
          import('features/tagging/routes/liveTaggingListModule').then(
            (module) => module.liveTaggingList,
          ),
      },
      {
        path: ':matchId',
        import: () =>
          import('features/tagging/routes/liveTaggingDetailModule').then(
            (module) => module.liveTaggingDetail,
          ),
      },
    ],
  },

  // playlists
  {
    path: 'playlists',
    children: [
      {
        path: '/',
        import: () =>
          import('features/playlists/routes/playlistsOverviewModule').then(
            (module) => module.playlistsOverviewModule,
          ),
      },
      {
        path: ':playlistId',
        import: () =>
          import('features/playlists/routes/playlistsDetailModule').then(
            (module) => module.playlistDetailModule,
          ),
      },
    ],
  },

  // players
  {
    path: 'players',
    children: [
      {
        path: '/',
        import: () =>
          import('features/players/routes/playersModule').then(
            (module) => module.playersModule,
          ),
      },
    ],
  },

  // calendar
  {
    path: 'matchcalendar',
    children: [
      {
        path: '/',
        import: () =>
          import('features/calendar/routes/matchCalendarModule').then(
            (module) => module.matchCalendarModule,
          ),
      },
      {
        path: 'add',
        import: () =>
          import('features/calendar/routes/addMatchModule').then(
            (module) => module.addMatchModule,
          ),
      },
    ],
  },

  {
    path: 'sharing',
    children: [
      {
        path: ':shareKey',
        import: () =>
          import('features/sharing/routes/addSharedMatchModule').then(
            (module) => module.addSharedMatchModule,
          ),
      },
    ],
  },

  //
  // users
  {
    path: 'users',
    children: [
      {
        path: '/',
        import: () =>
          import('features/users/routes/usersModule').then(
            (module) => module.usersModule,
          ),
      },
      {
        path: 'add',
        import: () =>
          import('features/users/routes/addUsersModule').then(
            (module) => module.addUsersModule,
          ),
      },
    ],
  },

  // devices
  {
    path: 'devices',
    children: [
      {
        path: '/',
        import: () =>
          import('features/devices/routes/devicesModule').then(
            (module) => module.devicesModule,
          ),
      },
      {
        path: 'admin',
        import: () =>
          import('features/devices/routes/devicesAdminModule').then(
            (module) => module.devicesAdminModule,
          ),
      },
      {
        path: ':deviceId',
        import: () =>
          import('features/devices/routes/deviceDetailModule').then(
            (module) => module.deviceDetailModule,
          ),
      },
    ],
  },

  // settings
  {
    path: 'settings',
    children: [
      {
        path: '/',
        import: () =>
          import('features/settings/routes/settingsModule').then(
            (module) => module.settingsModule,
          ),
      },
    ],
  },
  // {
  //   path: 'under-construction',
  //   children: [
  //     {
  //       path: '/',
  //       import: () =>
  //         import(
  //           'features/under-construction/routes/underConstructionModule'
  //         ).then((module) => module.underConstructionModule),
  //     },
  //   ],
  // },

  // fallback -- passing no route is equivalent to passing `path: '*'`
  {
    element: <Navigate to="matches" />,
  },
]
