import { createJSONStorage, persist } from 'zustand/middleware'
import { create } from 'zustand'

import { Club, Theme } from '../types'

type ClubStore = {
  club?: Club
  setClub: (club: Club) => void
}

type ThemeStore = {
  theme: Theme
  setTheme: (theme: Theme) => void
}

export const useClubStore = create<ClubStore>()(
  persist(
    (set) => ({
      club: undefined,
      setClub: (club: Club) => {
        set({ club })
      },
    }),
    { name: 'club', storage: createJSONStorage(() => localStorage) },
  ),
)

export const useThemeStore = create<ThemeStore>()(
  persist(
    (set) => ({
      theme: {
        mode: 'light',
        primaryColor: '#FF4500',
      },
      setTheme: (theme: Theme) => {
        set({ theme })
      },
    }),
    { name: 'theme', storage: createJSONStorage(() => localStorage) },
  ),
)
