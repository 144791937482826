import Keycloak, { KeycloakInitOptions } from 'keycloak-js'

export const roles = [
  'admin',
  'analyst',
  'coach',
  'local_video',
  'match_downloader',
  'match_planner',
  'painter',
  'viewer',
  'device_manager',
  'stats_viewer',
  'sharer',
  'training_planner',
  'user_manager',
  'preview',
  'sound',
] as const
export type Roles = typeof roles[number]

export const keycloak = Keycloak(import.meta.env.VITE_KEYCLOAK_FILE)

export const keycloakInitOptions: (debug: boolean) => KeycloakInitOptions = (
  debug,
) => ({
  onLoad: 'login-required',
  checkLoginIframe: !debug,
})

// for React components use the custom hook `useAuth()`
export const hasAnyRoles = (
  userRoles: Roles[],
  keycloakClient: Keycloak.KeycloakInstance,
): boolean => {
  const keycloakRoles = keycloakClient.resourceAccess?.replay.roles ?? []
  return userRoles.some((r) => keycloakRoles.indexOf(r) > -1)
}

// for React components use the custom hook `useAuth()`
export const hasAllRoles = (
  userRoles: Roles[],
  keycloakClient: Keycloak.KeycloakInstance,
): boolean => {
  const keycloakRoles = keycloakClient.resourceAccess?.replay.roles ?? []
  return userRoles.every((r) => keycloakRoles.indexOf(r) > -1)
}
