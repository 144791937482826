import { Badge, IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { NotificationsSharp as NotificationsIcon } from '@mui/icons-material'

import { theme } from 'styles/theme'

import { ChangeCrabWindow } from '../changeCrabTypes'

declare let window: ChangeCrabWindow

export const ChangeCrabWidget = () => {
  const [unseen, setUnseen] = useState<number | undefined>()

  useEffect(() => {
    window.changecrab_config = {
      projectid: 'fBpCdy',
      selector: '.changebubble',
      type: 'full',
      emptycolor: 'rgb(0 0 0)',
      activecolor: 'rgb(255 74 28)',
      set unseen(us: number) {
        setUnseen(us)
      },
    }

    const script = document.createElement('script')

    script.src = 'https://changecrab.com/embed/embed.js'
    script.async = true

    script.onload = () => {}

    document.head.appendChild(script)

    const style = document.createElement('style')
    style.innerHTML = `.crab-count { display: none !important; }`
    document.head.appendChild(style)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <IconButton
      color="inherit"
      style={{
        position: 'absolute',
        zIndex: 500,
        top: '8px',
        right: '8px',
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        display: unseen && unseen > 0 ? 'block' : 'none',
      }}
    >
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className="changebubble">
        <Badge
          badgeContent={unseen}
          color="secondary"
          className="mt-[-0.2rem]"
          onClick={() => {
            setUnseen(undefined)
          }}
        >
          <NotificationsIcon />
        </Badge>
      </div>
    </IconButton>
  )
}
