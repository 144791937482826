import * as Sentry from '@sentry/react'
import { Outlet, ReactLocation, Router } from '@tanstack/react-location'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { IntlProvider } from 'react-intl'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { configure as configureAxiosHooks } from 'axios-hooks'
import { hotjar } from 'react-hotjar'
import { useEffect } from 'react'

import { keycloak, keycloakInitOptions } from 'lib/keycloak'
import { Analytics } from 'features/app/components/Analytics'
// import { GTagProvider } from 'components/GTag'
import { Layout } from 'features/app/components/Layout'
import { Splash } from 'features/app/components/Splash'
import { clearOldPlaylistsFromCache } from 'features/playlists/cache/clear'
import { messages } from 'i18n'
import { replay } from 'lib/axios'
import { routes } from 'features/app/routes'
import { theme } from 'styles/theme'
import { useLocale } from 'features/app/hooks/useLocale'

import { IntroductionModalProvider } from '../components/IntroductionModalProvider'
import { PendingLoader } from '../components/PendingLoader'

const location = new ReactLocation()
const queryClient = new QueryClient()

const App = () => {
  const [locale] = useLocale()

  useEffect(() => {
    configureAxiosHooks({ axios: replay })
    clearOldPlaylistsFromCache()

    document.body.style.backgroundColor = theme.palette.background.default

    if (import.meta.env.VITE_HOTJAR_ID) {
      hotjar.initialize(
        import.meta.env.VITE_HOTJAR_ID,
        import.meta.env.VITE_HOTJAR_SNIPPET_VERSION,
        import.meta.env.DEV,
      )
    }
  })

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakInitOptions(import.meta.env.DEV)}
      onTokens={() => {
        if (hotjar.initialized()) {
          hotjar.identify(keycloak.subject || null, {})
        }
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Router
          location={location}
          routes={routes}
          defaultPendingMs={300}
          defaultPendingMinMs={500}
          defaultPendingElement={<PendingLoader />}
        >
          <IntlProvider locale={locale} messages={messages[locale]}>
            {/* FIXME: can remove after refactoring everything to using emotion as the style engine https://mui.com/guides/migration-v4/#style-library */}
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Splash>
                  {/* <GTagProvider
                    trackingId={import.meta.env.VITE_GTM_MEASUREMENT_ID}
                  > */}
                  <Analytics>
                    <Layout>
                      <IntroductionModalProvider>
                        <Outlet />
                      </IntroductionModalProvider>
                    </Layout>
                  </Analytics>
                  {/* </GTagProvider> */}
                </Splash>
              </ThemeProvider>
            </StyledEngineProvider>
          </IntlProvider>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ReactKeycloakProvider>
  )
}

// eslint-disable-next-line import/no-default-export
export default Sentry.withProfiler(App)
