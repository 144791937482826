/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable max-classes-per-file */
/* eslint-disable consistent-return */
/* eslint-disable promise/always-return */
/* eslint-disable promise/no-nesting */
// TODO: get rid of Dexie and use https://github.com/jakearchibald/idb
import Dexie, { Table } from 'dexie'

// IMPORTANT! - When you need to modify the schema, also update the version number
// --> https://dexie.org/docs/Tutorial/Understanding-the-basics#modify-schema
export const DB_VERSION = 13

// This is needed so we can drop all previous data
// NOTE: https://github.com/dexie/Dexie.js/issues/349
class MyDexie extends Dexie {
  private static readonly targetVersion = DB_VERSION

  public open() {
    if (this.isOpen()) return super.open()

    return Dexie.Promise.resolve()
      .then(() => Dexie.exists(this.name))
      .then((exists) => {
        if (!exists) {
          // no need to check database version since it doesn't exist
          return
        }

        // Open separate instance of dexie to get current database version
        return new Dexie(this.name).open().then(async (db) => {
          if (db.verno >= MyDexie.targetVersion) {
            // database up to date (or newer)
            return db.close()
          }

          // eslint-disable-next-line no-console
          console.log(
            `Database schema out of date, resetting all data. (currentVersion: ${db.verno}, expectedVersion: ${MyDexie.targetVersion})`,
          )
          await db.delete()

          // ensure the delete was successful
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const exists = await Dexie.exists(this.name)
          if (exists) {
            throw new Error('Failed to remove mock backend database.')
          }
        })
      })
      .then(() => super.open())
  }
}

export interface Clips {
  eventId: string
  type: 'panoramic' | 'tactical'
  blob: Blob
  timestamp: number
}

export interface Playlists {
  playlistId: string
  timestamp: number
}

export class Zone14Videos extends MyDexie {
  clips!: Table<Clips>

  playlists!: Table<Playlists>

  constructor() {
    super('zone14EventClips')
    this.version(DB_VERSION).stores({
      clips: '[eventId+type], timestamp', // Primary key and indexed props
      playlists: 'playlistId, timestamp', // Primary key and indexed props
    })
  }
}

export const db = new Zone14Videos()
