import { useEffect, useMemo } from 'react'
import { useRouter } from '@tanstack/react-location'

import { trackPageView } from 'features/app/analytics'

export const Analytics = ({ children }: { children: JSX.Element }) => {
  const router = useRouter()
  const pathName = useMemo(
    () => router.state.matches.slice(-1).pop()?.pathname,
    [router.state.matches],
  )

  useEffect(() => {
    trackPageView({ page: pathName })
  }, [pathName])

  return children
}
