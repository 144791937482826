import { ReactNode } from 'react'
// import { useRouter } from '@tanstack/react-location'

import { IntroductionModal } from 'components/IntroductionModal'

import { useIntroductionModal } from '../hooks/useIntroductionModal'

export const IntroductionModalProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  // TODO: use if going to use route-dependent screens!
  // const router = useRouter()
  /* const pathName = useMemo(
    () => router.state.matches.slice(-1).pop()?.pathname,
    [router.state.matches],
  ) */

  const { introductionModals, setShown } = useIntroductionModal()

  const currentModal =
    introductionModals && introductionModals.length > 0
      ? introductionModals[0]
      : undefined

  const handleClose = () => {
    if (currentModal !== undefined) {
      setShown(currentModal.id)
    }
  }
  return (
    <>
      {currentModal !== undefined && (
        <IntroductionModal modal={currentModal} onClose={handleClose} />
      )}
      {children}
    </>
  )
}
