import {
  ChevronLeftSharp as ChevronLeftIcon,
  ChevronRightSharp as ChevronRightIcon,
  DarkModeSharp,
  DateRangeSharp as DateRangeIcon,
  ExitToAppSharp as ExitToAppIcon,
  GroupSharp as GroupIcon,
  LightModeSharp,
  PlayCircleOutlineSharp as PlayCircleOutlineIcon,
  SettingsSharp as SettingsIcon,
  SmartphoneSharp as SmartphoneIcon,
  SubscriptionsSharp as SubscriptionsIcon,
} from '@mui/icons-material'
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  useTheme,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'

import { useClubStore, useThemeStore } from 'features/club/store'
import { BoxIcon } from 'features/app/components/BoxIcon'
import { Link } from 'components/Link'
import { Zone14Icon } from 'features/app/components/Zone14Icon'
import { useAuth } from 'features/app/hooks/useAuth'
import { useMenuOpen } from 'features/app/hooks/useMenuOpen'
import { useResponsive } from 'features/app/hooks/useResponsive'

const drawerHidden = 100
const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      borderRight: 'none',
      color: theme.palette.text.primary,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      display: 'flex',
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: drawerHidden,
      [theme.breakpoints.up('md')]: {
        width: drawerHidden,
      },
      borderRight: 'none',
      // backgroundColor: 'rgba(255,255,255,0.2)',
    },
    toolbar: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      display: 'flex',
      alignItems: 'center',
      /* justifyContent: 'flex-end', */
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    listItemButton: {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'column',
      },
      borderRadius: '24px',
      '&:hover': {
        Color: theme.palette.primary.main, // TODO: find out why not working!
      },
    },
    listItemIcon: {
      [theme.breakpoints.up('md')]: {
        minWidth: 'auto',
      },
    },
  }),
)

export const Sidebar = () => {
  const { club } = useClubStore()
  const classes = useStyles()
  const muitheme = useTheme()
  const [open, toggleMenuOpen] = useMenuOpen()
  const {
    hasUserManagerRole,
    hasDeviceManagerRole,
    hasAnalystRole,
    hasCoachRole,
    hasViewerRole,
    hasMatchPlannerRole,
    logout,
  } = useAuth()

  const { isMobile } = useResponsive()

  const { theme, setTheme } = useThemeStore()

  return (
    <Drawer
      variant="permanent"
      onClick={() => {
        if (isMobile) {
          toggleMenuOpen()
        }
      }}
      className={clsx([
        classes.drawer,
        open && classes.drawerOpen,
        !open && classes.drawerClose,
      ])}
      classes={{
        paper: clsx([open && classes.drawerOpen, !open && classes.drawerClose]),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton
          onClick={() => {
            // the click event fires 2 times, one from the drawer's onClick, if we don't stop it here
            if (!isMobile) {
              toggleMenuOpen()
            }
          }}
        >
          {muitheme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      {!isMobile && (
        <IconButton
          style={{ color: '#FF4500', fontSize: '2rem', height: '6rem' }}
          disabled
        >
          <Zone14Icon />
        </IconButton>
      )}
      <p className="m-0 whitespace-normal p-1 text-center text-sm md:max-w-[100px] md:text-xs">
        {' '}
        {club?.name}
      </p>
      <List className="flex grow flex-col">
        {/* <ListItem disablePadding /> */}
        <ListItem disablePadding />
        {(hasCoachRole || hasAnalystRole || hasViewerRole) && (
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/matches"
              className={clsx([classes.listItemButton])}
              // selected={location.pathname.split('/')[1] === 'matches'}
            >
              <ListItemIcon className={clsx([classes.listItemIcon])}>
                <PlayCircleOutlineIcon />
              </ListItemIcon>
              <ListItemText
                primary={<FormattedMessage id="sidebar.matches" />}
                disableTypography
                style={{ fontSize: '0.85rem' }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {(hasCoachRole || hasAnalystRole) && (
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/live"
              className={clsx([classes.listItemButton])}
            >
              <ListItemIcon className={clsx([classes.listItemIcon])}>
                <SmartphoneIcon />
              </ListItemIcon>
              <ListItemText
                primary={<FormattedMessage id="sidebar.live" />}
                disableTypography
                style={{ fontSize: '0.85rem' }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {(hasCoachRole || hasAnalystRole || hasViewerRole) && (
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/playlists"
              className={clsx([classes.listItemButton])}
            >
              <ListItemIcon className={clsx([classes.listItemIcon])}>
                <SubscriptionsIcon />
              </ListItemIcon>
              <ListItemText
                primary={<FormattedMessage id="sidebar.playlists" />}
                disableTypography
                style={{ fontSize: '0.85rem' }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {(hasCoachRole || hasMatchPlannerRole) && (
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/matchcalendar"
              className={clsx([classes.listItemButton])}
            >
              <ListItemIcon className={clsx([classes.listItemIcon])}>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText
                primary={<FormattedMessage id="sidebar.matchcalendar" />}
                disableTypography
                style={{ fontSize: '0.85rem' }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {hasUserManagerRole && (
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/users"
              className={clsx([classes.listItemButton])}
            >
              <ListItemIcon className={clsx([classes.listItemIcon])}>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText
                primary={<FormattedMessage id="sidebar.users" />}
                disableTypography
                style={{ fontSize: '0.85rem' }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {hasDeviceManagerRole && (
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/devices"
              className={clsx([classes.listItemButton])}
            >
              <ListItemIcon className={clsx([classes.listItemIcon])}>
                <BoxIcon />
              </ListItemIcon>
              <ListItemText
                primary={<FormattedMessage id="sidebar.devices" />}
                disableTypography
                style={{ fontSize: '0.85rem' }}
              />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem disablePadding className="mt-auto">
          <ListItemButton
            component="a"
            className={clsx([classes.listItemButton])}
            onClick={() => {
              setTheme({
                mode: theme.mode === 'light' ? 'dark' : 'light',
                primaryColor: theme.primaryColor,
              })
              window.location.reload()
            }}
          >
            <ListItemIcon className={clsx([classes.listItemIcon])}>
              {theme.mode === 'light' ? <DarkModeSharp /> : <LightModeSharp />}
            </ListItemIcon>
            <ListItemText
              primary={
                theme.mode === 'light' ? (
                  <FormattedMessage id="sidebar.darkmode" />
                ) : (
                  <FormattedMessage id="sidebar.lightmode" />
                )
              }
              disableTypography
              style={{ fontSize: '0.85rem' }}
            />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/settings"
            className={clsx([classes.listItemButton])}
          >
            <ListItemIcon className={clsx([classes.listItemIcon])}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage id="sidebar.settings" />}
              disableTypography
              style={{ fontSize: '0.85rem' }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={logout}
            className={clsx([classes.listItemButton])}
          >
            <ListItemIcon className={clsx([classes.listItemIcon])}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage id="logout" />}
              disableTypography
              style={{ fontSize: '0.85rem' }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  )
}
