import { CircularProgress, Grid } from '@mui/material'
import { ReactNode } from 'react'

import { useAuth } from 'features/app/hooks/useAuth'

type SplashProps = {
  children: ReactNode
}

export const Splash = (props: SplashProps) => {
  const { children } = props

  const { isAuthenticated } = useAuth()

  return (
    <div>
      {isAuthenticated ? (
        children
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '100vh' }}
        >
          <Grid item>
            <CircularProgress size="5rem" />
          </Grid>
        </Grid>
      )}
    </div>
  )
}
