import { create } from 'zustand'

type MenuOpenStore = {
  isMenuOpen: boolean
  toggleMenuOpen: () => void
}

export const useMenuOpenStore = create<MenuOpenStore>()((set) => ({
  isMenuOpen: false,
  toggleMenuOpen: () => {
    set(({ isMenuOpen }) => ({
      isMenuOpen: !isMenuOpen,
    }))
  },
}))

export const useMenuOpen = (): [boolean, () => void] => {
  const state = useMenuOpenStore()

  return [state.isMenuOpen, state.toggleMenuOpen]
}
