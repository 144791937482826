import { useCallback, useMemo } from 'react'
import { useKeycloak } from '@react-keycloak/web'

import { Roles } from 'lib/keycloak'

type UserAuth = {
  hasAdminRole: boolean
  hasAnalystRole: boolean
  hasCoachRole: boolean
  hasLocalVideoRole: boolean
  hasMatchDownloaderRole: boolean
  hasMatchPlannerRole: boolean
  hasShareRole: boolean
  hasPainterRole: boolean
  hasViewerRole: boolean
  hasStatsViewerRole: boolean
  hasDeviceManagerRole: boolean
  hasTrainingPlannerRole: boolean
  hasUserManagerRole: boolean
  hasPreviewRole: boolean
  hasSoundRole: boolean
  isAuthenticated: boolean
  isDemo: (clubId: string) => boolean
  logout: () => void
}

type KeycloakWithLicense = Keycloak.KeycloakTokenParsed & {
  license: {
    tenent: string
    plan: string
  }[]
}

// To be used inside UI components. For routing, use the helpers in `keycloak.ts`
export const useAuth = (): UserAuth => {
  const { keycloak } = useKeycloak()

  const keycloakRoles = useMemo(
    () => keycloak.resourceAccess?.replay.roles ?? [],
    [keycloak.resourceAccess?.replay.roles],
  )

  const hasRole = useCallback(
    (role: Roles) => keycloakRoles.includes(role),
    [keycloakRoles],
  )

  const isDemo = useCallback(
    (clubId: string) => {
      // idtoken parsed is [{tenent: '5f72fa2fe846b29f5f24be8c', plan: 'live'}]
      // check if the tenent is the demo club
      const tokenWithLicense = keycloak.idTokenParsed as KeycloakWithLicense
      if (tokenWithLicense && tokenWithLicense.license) {
        return tokenWithLicense.license.some(
          (token: { tenent: string; plan: string }) =>
            token.tenent === clubId && token.plan === 'demo',
        )
      }
      return false
    },
    [keycloak],
  )

  const logout = useCallback(() => {
    keycloak.logout()
  }, [keycloak])

  return useMemo(
    () => ({
      hasAdminRole: hasRole('admin'),
      hasAnalystRole: hasRole('analyst'),
      hasCoachRole: hasRole('coach'),
      hasLocalVideoRole: hasRole('local_video'),
      hasMatchDownloaderRole: hasRole('match_downloader'),
      hasMatchPlannerRole: hasRole('match_planner'),
      hasPainterRole: hasRole('painter'),
      hasViewerRole: hasRole('viewer'),
      hasStatsViewerRole: hasRole('stats_viewer'),
      hasShareRole: hasRole('sharer'),
      hasDeviceManagerRole: hasRole('device_manager'),
      hasTrainingPlannerRole: hasRole('training_planner'),
      hasUserManagerRole: hasRole('user_manager'),
      hasPreviewRole: hasRole('preview'),
      hasSoundRole: hasRole('sound'),
      isAuthenticated: keycloak.authenticated ?? false,
      isDemo,
      logout,
    }),
    [hasRole, isDemo, keycloak.authenticated, logout],
  )
}
