import { Zodios } from '@zodios/core'
import { ZodiosHooks } from '@zodios/react'

import { replay } from 'lib/axios'

import { endpoints } from './generated'

export const api = new Zodios(endpoints, { axiosInstance: replay })

export const apiHooks = new ZodiosHooks('replay', api)
