import { SvgIcon } from '@mui/material'

export const BoxIcon = () => (
  <SvgIcon>
    <path d="M19,5v14H5V5H19 M21,3H3v18h18V3z" />
    <path
      d="M7.7,16.3c0-0.8,0-1.7,0-2.5c0-0.6,0-1.3,0-1.9c0.6,0,1.1,0,1.7,0c0,0.9,0,1.8,0,2.8c1,0,1.9,0,2.8,0v0.1c0,0.5,0,1,0,1.5
	v0.1C10.7,16.3,9.2,16.3,7.7,16.3z"
    />
    <path
      d="M14.7,12.1c0-0.9,0-1.8,0-2.8c-0.9,0-1.9,0-2.8,0c0-0.6,0-1.1,0-1.7c1.5,0,3,0,4.5,0c0,1.5,0,3,0,4.4
	C15.8,12.1,15.2,12.1,14.7,12.1z"
    />
  </SvgIcon>
)
