import { alpha, createTheme, darken, lighten } from '@mui/material/styles'

import { useThemeStore } from 'features/club/store'

export const breakpoints = { xl: 1600, lg: 1200, md: 768, sm: 640, xs: 0 }

const rootElement = document.getElementById('root')

export const customAccentColors = {
  orange: '#FF4500',
  purple: '#6800FF',
  turquoise: '#00FFCC',
  blue: '#0028FF',
  green: '#00FF35',
  red: '#FF0023',
  yellow: '#FFC100',
  pink: '#FF00E7',
  grey: '#7C7C7C',
}

const customShadows = () => {
  const transparent = alpha('#000000', 0.16)

  return {
    z1: `0 1px 2px 0 ${transparent}`,
    z4: `0 4px 8px 0 ${transparent}`,
    z8: `0 8px 16px 0 ${transparent}`,
    z12: `0 12px 24px -4px ${transparent}`,
    z16: `0 16px 32px -4px ${transparent}`,
    z20: `0 20px 40px -4px ${transparent}`,
    z24: `0 24px 48px 0 ${transparent}`,
    //
    card: `0 0 2px 0 ${alpha('#000000', 0.08)}, 0 12px 24px -4px ${alpha(
      '#000000',
      0.08,
    )}`,
    paper: `0 0 2px 0 ${alpha('#000000', 0.08)}, 0 12px 24px -4px ${alpha(
      '#000000',
      0.08,
    )}`,
    dropdown: `0 0 2px 0 ${alpha(
      '#000000',
      0.24,
    )}, -20px 20px 40px -4px ${alpha('#000000', 0.24)}`,
    dialog: `-40px 40px 80px -8px ${alpha('#000000', 0.24)}`,
  }
}

const storedTheme = useThemeStore.getState().theme
const mainColor = storedTheme.primaryColor
const themeMode = storedTheme.mode

export const theme = createTheme({
  breakpoints: {
    values: breakpoints,
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
      marginBottom: '1rem',
      zIndex: 2,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.2rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  palette: {
    mode: themeMode,
    primary: {
      main: mainColor,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main:
        themeMode === 'light'
          ? lighten(mainColor, 0.5)
          : darken(mainColor, 0.5),
    },
    background: {
      default:
        themeMode === 'light'
          ? lighten(mainColor, 0.96)
          : darken(mainColor, 0.96),
      paper:
        themeMode === 'light' ? lighten(mainColor, 1) : darken(mainColor, 0.8),
    },
    text: {
      primary: themeMode === 'light' ? '#000000' : '#FFFFFF',
    },
  },
  mixins: {
    toolbar: {
      height: '64px',
    },
  },
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: customShadows().paper,
          backgroundColor:
            themeMode === 'light'
              ? alpha(lighten(mainColor, 0.9), 0.9)
              : alpha(darken(mainColor, 0.9), 0.9),
          backdropFilter: 'blur(10px)',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 1000,
          fontSize: '0.75rem',
          /* '& .MuiSvgIcon-root': {
            color: '#FFFFFF',
          }, */
        },
        outlined: {
          borderTopWidth: 2,
          borderLeftWidth: 2,
          borderRightWidth: 2,
          borderBottomWidth: 2,
          ':hover': {
            borderTopWidth: 2,
            borderLeftWidth: 2,
            borderRightWidth: 2,
            borderBottomWidth: 2,
          },
          /* '& .MuiSvgIcon-root': {
            color: mainColor,
          }, */
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          boxShadow: customShadows().card,
          borderRadius: 16,
          backgroundColor:
            themeMode === 'light'
              ? lighten(mainColor, 1)
              : darken(mainColor, 0.8),
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: customShadows().card,
          zIndex: 2,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: customShadows().paper,
          zIndex: 2,
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          zIndex: 2,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'unset',
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        root: {
          borderRadius: 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          color: mainColor,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: alpha(mainColor, 0.5),
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 32,
          height: 20,
          padding: 0,
          display: 'flex',
          marginLeft: 8,
          marginRight: 8,
        },
        switchBase: {
          padding: 2,
          ':checked': {
            transform: 'translateX(12px)',
          },
          '&.Mui-checked': {
            transform: 'translateX(12px)',
          },
        },
        thumb: {
          width: 16,
          height: 16,
          borderRadius: 8,
        },
        track: {
          borderRadius: 32,
        },
      },
    },
  },
})
