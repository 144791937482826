import { useCallback, useEffect, useRef } from 'react'
import ReactGA from 'react-ga4'

export const trackPageView = ({ page }: { page?: string }) => {
  if (page) {
    ReactGA.send({
      hitType: 'pageview',
      page,
    })
  }
}

export const useLongEventTracking = (
  eventName: string,
  params: { [key: string]: string },
) => {
  const eventState = useRef<{
    leaves: number
    leavesDuration: number
    start: number | undefined
  }>({ leaves: 0, leavesDuration: 0, start: undefined })

  useEffect(() => {
    let leaveStart: number | undefined
    const handleVisibilityChange = () => {
      if (document.hidden) {
        leaveStart = Date.now()
      } else {
        if (!leaveStart) return

        const leaveDuration = (Date.now() - leaveStart) / 1000

        eventState.current.leavesDuration += leaveDuration
        eventState.current.leaves += 1

        leaveStart = undefined
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [eventState])

  const registerEventStart = useCallback(() => {
    eventState.current.start = Date.now()
  }, [eventState])

  const registerEventEnd = useCallback(() => {
    if (!eventState.current.start) return

    const eventDuration = (Date.now() - eventState.current.start) / 1000
    const netDuration = eventDuration - eventState.current.leavesDuration

    if (eventDuration < 1 || netDuration < 0) return // net duration < 0 normally only happens on hot reload

    ReactGA.event(eventName, {
      ...params,
      left_during_event: eventState.current.leaves,
      leave_time: eventState.current.leavesDuration,
      total_time: eventDuration,
      net_time: netDuration,
    })
  }, [eventState, eventName, params])

  return { registerEventStart, registerEventEnd }
}
