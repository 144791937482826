import { create } from 'zustand'

type HeadingStore = {
  heading: string
  setHeading: (heading: string) => void
}

export const useHeadingStore = create<HeadingStore>()((set) => ({
  heading: '',
  setHeading: (heading: string) => {
    set({ heading })
  },
}))
