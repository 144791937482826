import { createStyles, makeStyles } from '@mui/styles'
import { ReactNode } from 'react'
import { Theme } from '@mui/material'
import clsx from 'clsx'

import { ChangeCrabWidget } from 'features/releasenotes/components/ChangeCrabWidget'
import { DownloadsMenu } from 'features/downloads/components/DownloadsMenu'
import { Header } from 'features/app/components/Header'
import { Sidebar } from 'features/app/components/Sidebar'
import { useMenuOpen } from 'features/app/hooks/useMenuOpen'

import './App.css'

const drawerHidden = 100
const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      padding: theme.spacing(2),
      height: '100%',
      minHeight: 'calc(100vh - 80px)',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: `${drawerHidden}px`,
        width: `calc(100% - ${drawerHidden}px)`,
      },
      backgroundColor: theme.palette.background.default,
    },
    contentShift: {
      [theme.breakpoints.up('md')]: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  }),
)

export const Layout = ({ children }: { children: ReactNode }) => {
  const classes = useStyles()
  const [open] = useMenuOpen()
  return (
    <div className="App">
      <Header />
      <Sidebar />
      <ChangeCrabWidget />
      <DownloadsMenu />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {children}
      </main>
    </div>
  )
}
