import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { FormattedMessage } from 'react-intl'

type SlowType = 'slow' | 'superslow' | 'omg'

export const PendingLoader = () => {
  const [showSlow, setShowSlowLoad] = useState<SlowType>()

  useEffect(() => {
    const slowTimer = setTimeout(() => setShowSlowLoad('slow'), 3000)
    const superSlowTimer = setTimeout(() => setShowSlowLoad('superslow'), 15000)
    const omgSlowTimer = setTimeout(() => setShowSlowLoad('omg'), 25000)

    return () => {
      clearTimeout(slowTimer)
      clearTimeout(superSlowTimer)
      clearTimeout(omgSlowTimer)
    }
  }, [setShowSlowLoad])

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <CircularProgress size="5rem" className="mb-4" />
      {showSlow === 'slow' && (
        <>
          <FormattedMessage id="loading.slow" /> 👀
        </>
      )}
      {showSlow === 'superslow' && (
        <>
          <FormattedMessage id="loading.superslow" /> 🐌
        </>
      )}
      {showSlow === 'omg' && (
        <>
          <FormattedMessage id="loading.omgslow" /> 🤔
        </>
      )}
    </div>
  )
}
