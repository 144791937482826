import ReactGA from 'react-ga4'

export const initializeGA = (
  userId: string,
  club: string,
  lang: string,
  license: string,
) => {
  if (import.meta.env.VITE_GA_MEASUREMENT_ID) {
    ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID, {
      testMode: import.meta.env.DEV,
      // gaOptions: {
      //   debug_mode: true,
      // },
      // gtagOptions: {
      //   debug_mode: true,
      // },
    })

    ReactGA.set({
      user_id: userId,
      user_properties: {
        club,
        lang,
        license,
      },
    })
  }
}
