import { FormattedMessage } from 'react-intl'
import { IconButton /* Theme */ } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import useAxios from 'axios-hooks'
import { useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'

import { Club } from 'features/club/types'
import { initializeGA } from 'lib/google-analytics'
import { theme } from 'styles/theme'
import { useClubStore } from 'features/club/store'
import { useHeadingStore } from 'utils/headingStore'
import { useMenuOpen } from 'features/app/hooks/useMenuOpen'

import { useLocale } from '../hooks/useLocale'

const noClubSet = (currentClub: Club | undefined) => !currentClub
const userBelongsToCurrentlySetClub = (
  currentClub: Club | undefined,
  allClubs: Club[],
) => currentClub && allClubs.map((c) => c._id).includes(currentClub._id)

export const Header = () => {
  const { club, setClub } = useClubStore()
  const [open, toggleMenuOpen] = useMenuOpen()
  const heading = useHeadingStore((state) => state.heading)
  // const [countdown, setCountdown] = useState<string>('')
  // const [openDialog, setOpenDialog] = useState(false)
  const [locale] = useLocale()
  const [{ data: allClubs }] = useAxios<Club[]>('clubs')
  const { keycloak } = useKeycloak()

  // TODO: move to a more general place!
  useEffect(() => {
    if (!allClubs) return

    if (noClubSet(club) || !userBelongsToCurrentlySetClub(club, allClubs)) {
      setClub(allClubs[0])
    }
  }, [club, allClubs, setClub])

  useEffect(() => {
    if (!keycloak.subject || club === undefined) return
    const { license } = keycloak.tokenParsed as { license: Array<object> }
    let userLicense = 'basic'
    license.map((club_cred: any) => {
      if (club_cred.tenent === club._id) {
        userLicense = club_cred.plan
      }
      return null
    })

    const keycloakRoles = keycloak.resourceAccess?.replay.roles ?? []
    if (!keycloakRoles.includes('zone14')) {
      initializeGA(keycloak.subject, club.name, locale, userLicense)
    }
  }, [keycloak, club, locale])

  // useEffect(() => {
  //   const countdownDate = new Date('2024-04-04T09:00:00').getTime()

  //   const interval = setInterval(() => {
  //     const now = new Date().getTime()
  //     const distance = countdownDate - now

  //     const days = Math.floor(distance / (1000 * 60 * 60 * 24))
  //     const hours =
  //       days * 24 +
  //       Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  //     const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  //     const seconds = Math.floor((distance % (1000 * 60)) / 1000)

  //     setCountdown(`${hours}h ${minutes}m ${seconds}s `)
  //   }, 1000)

  //   return () => clearInterval(interval) // Clear interval on component unmount
  // }, [])

  return (
    <div
      style={{ backgroundColor: theme.palette.background.default }}
      className="relative flex items-center"
    >
      <IconButton
        edge="start"
        aria-label="menu"
        color="primary"
        className={`${open ? 'hidden' : ''} m-4 max-h-8 md:hidden`}
        onClick={toggleMenuOpen}
        style={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: '8px',
        }}
      >
        <MenuIcon />
      </IconButton>
      <h1 className="m-0 py-4 text-left md:pl-[120px]">
        {heading && <FormattedMessage id={heading} />}
      </h1>
      <br />
      {/* <Button
        color="inherit"
        onClick={() => setOpenDialog(true)}
        className="text-left text-xs md:text-sm"
      >
        Design Preview ✨
        <br />
        {countdown}
      </Button>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Replay in fancy 💅✨</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="newtheme.text" values={{ break: <br /> }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Ok Cool
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  )
}
