import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'

import { api, apiHooks } from 'api'

// eslint-disable-next-line no-restricted-imports
import existingIntroductionModals from '../../../resources/IntroductionModal/introductionModals.json'

import { ReplayUserUpdate } from './useIntroductionModalTypes'

export type ItroductionModalStep = {
  title: string
  video: string
  text: string
}

export type IntroductionModalObj = {
  id: string
  heading: string
  steps: Array<ItroductionModalStep>
}

const useMutateUser = () => {
  const queryClient = useQueryClient()

  const key = apiHooks.getKeyByPath('get', '/users/current_user', {})

  return useMutation(({ user }) => api.patch('/users/current_user', user), {
    // When mutate is called:
    onMutate: async ({ user }: { user: ReplayUserUpdate }) => {
      queryClient.cancelQueries(key)

      const previousUser = queryClient.getQueryData<ReplayUserUpdate>(key)

      const updatedUser = { ...previousUser, ...user }

      queryClient.setQueryData(key, updatedUser)

      return { previousUser }
    },
    onError: () => {
      // don't do anything on error to not bother the user if there is a problem at the server
    },
    onSuccess: () => {
      // normally we would invalidate the onSettled, here we only do on success
      queryClient.invalidateQueries(key)
    },
  })
}

export const useIntroductionModal = () => {
  const { data: replayUser, isLoading } = apiHooks.useGet(
    '/users/current_user',
    undefined,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  )

  const introductionModals = useMemo(() => {
    if (replayUser === undefined) return undefined

    if (replayUser.shownIntroductionScreens.length === 0)
      return existingIntroductionModals

    return existingIntroductionModals.filter(
      (introductionModal) =>
        !replayUser.shownIntroductionScreens.includes(introductionModal.id),
    )
  }, [replayUser])

  const { mutate, isLoading: isLoadingMutate } = useMutateUser()

  const setShown = (id: string) => {
    const user: ReplayUserUpdate = {
      shownIntroductionScreens: [id],
    }
    return mutate({ user })
  }

  return { introductionModals, isLoading, isLoadingMutate, setShown }
}
