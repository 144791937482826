import { SvgIcon } from '@mui/material'

export const Zone14Icon = () => (
  <SvgIcon fontSize="large">
    <g>
      <polygon points="0,11.5 5.2,11.5 5.2,18.7 12.5,18.7 12.5,23.9 0,23.9 	" />
      <polygon points="11.5,0.1 24,0.1 24,12.5 18.8,12.5 18.8,5.3 11.5,5.3 	" />
    </g>
  </SvgIcon>
)
